import { COVER_LETTER_SHOWN_PREFIX, GOOGLE_TRANSLATE, subContainerOverrideClass, userDataNamesMap } from 'constants/liveViewPage';
import FormInput from '../../form-comps/FormInput/FormInput';
import FormMultiLine from '../../form-comps/FormMultiLine/FormMultiLine';
import {
  LiveViewFee,
  FeeStructure,
  ReviewSectionPage,
  LiveViewForm,
  LiveViewCoverLetter,
  FormData,
  LiveViewPaymentConfig,
  LiveViewElement,
  LiveViewPage,
  FormState,
  ControlState,
  LiveViewRow,
  LiveViewFormSectionBreakSetting,
  ShowLabelValue,
  SubmissionData,
  LabelAlignment,
  ReadOnlyValue,
  ShowChooseOneValue,
  UserDataT,
} from 'types/liveView';
import { PaymentType } from 'types/paymentSettings';
import * as formulajs from '@formulajs/formulajs';
import * as numeral from 'numeral';
// @ts-ignore
import { parse, ConstantNode } from 'mathjs';
import Big from 'big.js';
import { elementsForPreview } from 'constants/liveViewPage';
import { signNowLink } from 'utils/routing';

/** Redirects to Thank You page in case if thankYouPageToken was received. */
export const redirectAfterSubmission = (
  submitResult: { thankYouPageToken: string, submissionId: string },
  formData: FormData,
  formId: string,
  router: { push: (path: string) => void },
  isRedirectingToDoc: boolean,
): boolean => {
  if (submitResult && submitResult.thankYouPageToken) {
    // if submit was successful, the cover letter will be shown again when entering the same form
    sessionStorage.removeItem(`${COVER_LETTER_SHOWN_PREFIX}${formId}`);

    if (isRedirectingToDoc) {
      // redirect to the Doc view to sign the document
      const { submissionId } = submitResult;
      const linkToSignDoc = signNowLink({ signerKey: '', signeeType: '', fullname: '', email: '', submissionId, formId });
      window.location.href = linkToSignDoc;
      return true;
    }

    const customRedirectUrl: string | null = formData?.form?.formSettings?.thankYouRedirectSettings?.url
      ? formData.form.formSettings.thankYouRedirectSettings.url
      : null;
    if (customRedirectUrl) {
      // react-router-redux does not change the domain, so we need to replace the URL
      window.location.href = customRedirectUrl;
    } else {
      // react-router-redux allows us to avoid resetting app's state, so we don't need to make
      // extra calls to the BE on the thank you page in order to get thank you page custom settings
      router.push('/ng/f/thankyou');
    }
    return true;
  }
  return false;
};

/** Redirects to Log In page if error code is 401 or 403. */
export const redirectToLogin = (
  coverLetter: LiveViewCoverLetter,
  formData: FormData,
  formDataError: any,
  formId: string,
  formAlias: string,
): boolean => {
  const { settings } = coverLetter;
  if (coverLetter && !formData && formDataError) {
    if ([401, 403].includes(formDataError.status)) {
      // TODO : redirect or show login/signup flow.
      if (!settings?.useCoverLetter) {
        const redirectUrl = formAlias ? `/ng/fa/${formAlias}` : `/ng/f/${formId}`;
        location.href = `/ng/login?redirect_url=${redirectUrl}`;
      }
      return true;
    }
    // eslint-disable-next-line no-console
    console.error('There was an error fetching the form', formDataError);
    return true;
  }
  return false;
};

/** Redirects to 404 page if error code is 404. */
export const redirectTo404 = (
  formData: FormData,
  formDataError: any,
  router: {
    push: (path: string) => void;
  },
): boolean => {
  if (!formData && formDataError && formDataError?.status === 404) {
    router.push('/ng/404');
    return true;
  }
  return false;
};

/** Returns label for payment by card depending on Pre-Authorize value. */
export const getSubmitCardLabel = (paymentType: PaymentType, paymentConfig: LiveViewPaymentConfig): string => {
  if (paymentType === 'credit_card' && paymentConfig.authorizeOnly ||
    paymentType === 'debit_card' && paymentConfig.isDebitAuthEnabled) {
    return 'Pre-Authorize Now';
  }
  return 'Pay Now';
};

export const scrollToFormTop = (offset: number = 0): void => {
  const top: Element | null = document.querySelector(`.${subContainerOverrideClass}`);
  if (top) {
    top.scrollTop = offset;
  }
};

export const scrollFieldIntoView = (id: string) => {
  const idSelector = '#' + id;
  const elem = document.querySelector(idSelector) as HTMLElement;
  // scrollIntoView doesn't seem to bring the element into full view. using this instead.
  scrollToFormTop(elem?.offsetTop - 30);
};

/** Searches for a Google Translate element through all the form pages. */
export const findGoogleTranslateElement = (pages: LiveViewPage[]): LiveViewElement | undefined => pages
  .flatMap(page => page.rows)
  .flatMap(row => row.elements)
  .find(el => el.elementType === GOOGLE_TRANSLATE);

/** Returns true if the page is valid, otherwise false. */
export const validatePage = (refs: React.MutableRefObject<{}>, currentPage: number): boolean => {
  let isValid = true;
  const currentPageRefs = refs.current[currentPage];
  let scrollId: string | null = null;
  for (const property in currentPageRefs) {
    if (currentPageRefs[property] && currentPageRefs[property].validate) {
      const fieldValid = currentPageRefs[property].validate();
      if (!scrollId && !fieldValid) {
        scrollId = property;
        scrollFieldIntoView(scrollId);
      }
      isValid = fieldValid && isValid;
    }
  }
  return isValid;
};

function isObject(val: any) {
  return typeof val === 'object' && val !== null;
}

function getCalcMap(nState) {
  return Object.entries(nState)
    .reduce((obj, [k, v]: [string, {calculations}]) => {
      if (!v?.calculations) return obj;
      if (isObject(v.calculations)) {
        Object.keys(v.calculations).forEach(ck => {
          obj[ck] = v.calculations[ck];
        });
      } else {
        obj[k] = v.calculations;
      }

      return obj;
    }, {});
}

const scopeExtension = {
  DOLLAR: (number, decimals = 2) => numeral(decimals <= 0 ?
    Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals) : number
  ).format(decimals <= 0 ? '($0,0)' : '($0,0.' + new Array(decimals + 1).join('0') + ')'),
};

export function calculateFormulas(formulas, nState) {
  const parsedFormulas = new Map();
  try {
    const calcs = getCalcMap(nState);

    formulas.forEach((val, k) => {
      const isWizardElement = /^plugin_/.test(k);
      const result = parse(val.formula).transform(node => {
        if (node.isSymbolNode) {
          if (formulajs[node.name]) return node; // function to activate from scope
          // @ts-ignore
          if (calcs.hasOwnProperty(node.name) || calcs.hasOwnProperty(`total_${node.name}`)) {
            return new ConstantNode(Number(isWizardElement && typeof calcs[`total_${node.name}`] !== 'undefined' ?
              // @ts-ignore
              calcs[`total_${node.name}`] : calcs[node.name]));
          }
          return new ConstantNode(0);
        }
        return node;
      }).compile().evaluate(Object.assign(formulajs, scopeExtension));
      parsedFormulas.set(k, {...val, result});
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unable to calculate formula', e);
  }
  return parsedFormulas;
}

export const applyCalculations = (nState: FormState, formulas): void => {
  const calcValues = calculateFormulas(formulas, nState);
  let shouldRecalculate: boolean = false;
  calcValues.forEach((v, k) => {
    const fieldState = nState[k] as ControlState;
    nState[k] = {
      ...fieldState,
      fields: { // apply the same value to possible target field type
        [v.Comp === FormMultiLine ? 'multiline' : v.eProps.type /* FormInput, probably */]: v.result,
      },
    };

    if (v.Comp === FormInput && nState[k].calculations !== v.result && Array.from(formulas.values())
      .some((f: any) => f.formula?.includes(k))) {
      nState[k].calculations = v.result;
      shouldRecalculate = true;
    }
  });

  if (shouldRecalculate) {
    applyCalculations(nState, formulas);
  }
};

export const isShowHide = (field: string, value: any, formData: FormData, formState: FormState): boolean => {
  const pages: LiveViewPage[] = formData?.form?.pages ? formData.form.pages : [];
  const formElements: LiveViewElement[] = pages.flatMap(page => page.rows.flatMap(row => row.elements));
  const comparedElement: LiveViewElement | undefined = formElements.find(elem => elem.id === field);
  const selected = formState[field]?.fields.selected;
  if (comparedElement?.elementType === 'dropdown') {
    const indexVal = parseInt(value.slice(-1), 10);
    const newVal = comparedElement.options ? comparedElement.options[indexVal]?.id : null;

    return Array.isArray(selected) ? selected?.includes(newVal) : selected === newVal;
  }

  return Array.isArray(selected) ?
    selected?.includes(value) || selected?.find(sel => sel?.value === value)
    : selected === value;
};

export function transformToProps(
  { options, ...props }: LiveViewElement, defaultProps) {
  const eProps = {
    ...defaultProps,
    ...props,
  };

  if (props.formula) {
    eProps.readOnly = true;
  }

  if (options && options.length) {
    if (props.elementType === 'ng_checkboxes') {
      const newOptions = options[0]?.id?.split(/,|\||\n/);
      eProps.options = newOptions.map((option, index) =>
        ({ label: option,
          value: `${option}_${index}`,
          calculation: option[0]?.calculation || null,
          originalElementId: option[0]?.originalElementId || null,
        })) || [];
    } else {
      eProps.options = options.map(
        ({ id: nId, label: nLabel, calculation, originalElementId }) =>
          ({ label: nLabel, value: nId, calculation, originalElementId })) || [];
    }
  }
  return eProps;
}

function isValidDate(date: Date | unknown) {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date as number);
}

function formatDate(date: Date | any) {
  if (!isValidDate(date)) return null;
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

export const defaultGetFields = (value: ControlState, id: string) =>
  Object.entries(value.fields)
    .filter(([key]) => key !== 'extraData')
    .map(([key, raw]) => {
      if (Array.isArray(raw)) {
        return raw.map(r => ({id, raw: r?.value || r }));
      }
      return [{
        id: key,
        raw: formatDate(raw) || String(raw),
      }];
    }).flatMap(x => x);

export const getCheckboxOrRadioValues = (val: ControlState, id: string): {id: string, raw: string}[] =>
  Object.entries(val.fields).map(([, raw]) =>
    raw.map(({ value, label }: { value: string, label: string }) =>
      // replace 'other choice' id with the value manually entered by user
      value === `other_choice_${id}` ?
        { id, raw: label } :
        { id, raw: value }
    )).flatMap(x => x);

export const getNgCheckboxesValues = (val: ControlState, id: string): {id: string, raw: string}[] =>
  Object.entries(val.fields).map(([, raw]) =>
    raw.map(({ label }: { label: string }) => ({ id, raw: label }))).flatMap(x => x);

export const getSurveyMatrixValues = (val: ControlState, id: string): {id: string, raw: string}[] => {
  const formattedString: string = Object.entries(val.fields).map(([, raw]) => raw.map(r => {
    if (r.length <= 1) {
      return '';
    }
    return `${r[0].value}: ${r.splice(1, r.length).map(v => v.value).join(', ')}`;
  })).filter(v => v.length).join(', ');
  return [{ id, raw: formattedString }];
};

export const getUserDataValues = (val: ControlState, id: string): {id: string, raw: string}[] => {
  const userDataObject: UserDataT = val.fields['User Data'];
  const formattedString: string = Object.entries(userDataObject)
    .map(([key, value]) => `${userDataNamesMap[key]}: ${value}`).join(', ');

  return [{ id, raw: formattedString }];
};

export const getCheckAndTextValues = (val: ControlState, id: string): {id: string, raw: string}[] => {
  const values = Object.entries(val.fields)[0][1];
  const formattedString: string = `${values.checked ? values.option : ''} ${values.text}`;

  return [{ id, raw: formattedString }];
};

export const getFields = (value: ControlState, id: string) => {
  switch (value.extraData?.elementType) {
    case 'radio':
    case 'checkbox':
      return getCheckboxOrRadioValues(value, id);
    case 'ng_checkboxes':
      return getNgCheckboxesValues(value, id);
    case 'survey_matrix':
      return getSurveyMatrixValues(value, id);
    case 'check_and_text':
      return getCheckAndTextValues(value, id);
    case 'capture_user_session':
      return getUserDataValues(value, id);
    default:
      return defaultGetFields(value, id);
  }
};

export function formStateToSubmissionData(formState: FormState): SubmissionData[] {
  const { formId, formAlias, ...fields } = formState;

  const subData = Object.entries(fields)
    // ENG-2835: filter out all entries without actual values
    .filter(entry => (entry[1] ?? null) !== null)
    .map(([id, val]: [string, any]) => ({
      id,
      isEditable: true,
      fields: getFields(val, id),
    }));
  return subData;
}

/** Filters out elements by specified elementType for a row */
export const excludeElements = (row: LiveViewRow, elementTypes: string[] = ['translate']): LiveViewRow =>
  ({ ...row, elements: row.elements.filter(el => !elementTypes.includes(el.elementType)) });

export const hasAcknowledgedCoverLetter = (formId: string): string | null => sessionStorage.getItem(`${COVER_LETTER_SHOWN_PREFIX}${formId}`);

export const getCheckboxValue = (element: LiveViewElement, formState: FormState) => {
  // @ts-ignore
  const fieldIds: string[] = formStateToSubmissionData(formState).
    find(el => el.id === element.id)?.fields?.map(f => f.raw);
  // @ts-ignore
  const fieldLabels: string[] = fieldIds.map(id => element.options.find(op => op.id === id)?.label);

  return fieldLabels.join(', ');
};

/** Gets element value by Id. */
export const getElementValue = (id: string, formState: FormState): string =>
  formStateToSubmissionData(formState).find(el => el.id === id)?.fields[0]?.raw ||
  (document.getElementById(id) as HTMLInputElement)?.value;

/** Gets label for form's 'Next' button depending on section break settings. */
export const getNextButtonText = (
  sectionBreakSettings: Record<number, LiveViewFormSectionBreakSetting> | undefined,
  currentPage: number,
): string => {
  if (sectionBreakSettings && sectionBreakSettings[currentPage]?.nextBtnLabel) {
    return sectionBreakSettings[currentPage]?.nextBtnLabel;
  }

  return 'Next';
};

/** Gets label for form's 'Prev' button depending on section break settings. */
export const getPrevButtonText = (
  sectionBreakSettings: Record<number, LiveViewFormSectionBreakSetting> | undefined,
  currentPage: number,
): string => {
  if (sectionBreakSettings && sectionBreakSettings[currentPage - 1]?.prevBtnLabel) {
    return sectionBreakSettings[currentPage - 1]?.prevBtnLabel;
  }

  return 'Previous';
};

/** Gets label for form's 'Submit' button depending on payment and submit button settings. */
export const getSubmitButtonText = (
  settingsLabel: string,
  isRedirectingToDoc: boolean,
  paymentAmount: number,
  requiresPayment: boolean,
): string => {
  if (settingsLabel) {
    return settingsLabel;
  }
  if (isRedirectingToDoc) {
    return 'Continue...';
  }
  if (paymentAmount > 0 && requiresPayment) {
    return 'Pay & Submit';
  }
  if (paymentAmount > 0) {
    return 'Pay & Submit';
  }
  return 'Submit';
};


export function attachmentsToSubmissionData({pendingUploads /* filter out pendingUploads */, ...fAttachments}) {
  return Object.entries(fAttachments).map(([id, attachments]) => ({
    id,
    isEditable: true,
    fields: attachments.map(attachment => ({
      id: attachment.fileName,
      raw: attachment.url,
    })),
  }));
}

export function getFieldAmount(formState: FormState, fieldId: string): number {
  const fieldState = formState[fieldId];
  if (!fieldState?.fields) return 0;
  const raw: any = Object.values(fieldState.fields)[0] || 0;
  const result = parseFloat(typeof raw === 'string' ? raw.replace(/[$,]/g, '') : raw);
  return isNaN(result) ? 0 : result;
}

function getBaseAmount({amountSource, amountSourceType}: Partial<LiveViewPaymentConfig>, formState: FormState) {
  // @ts-ignore
  return amountSourceType === 'field' ? getFieldAmount(formState, amountSource) : parseFloat(amountSource);
}

function addFee(
  initial: number,
  flat: number = 0,
  percent: number = 0,
  isOffsetEnabled: boolean = false,
): number {
  const initialBig = Big(initial);
  const flatBig = Big(flat);
  const percentBig = Big(percent).div(100);

  if (isOffsetEnabled) {
    const numerator = initialBig.plus(flatBig);
    const denominator = Big(1).minus(percentBig);
    return numerator.div(denominator).round(2).toNumber();
  }

  const fee = initialBig.times(percentBig).plus(flatBig);
  return initialBig.plus(fee).round(2).toNumber();
}

export function addFeeType(initial: number, fee: LiveViewFee): number {
  if (fee.state !== 'enabled') return initial;
  switch (fee.feeType) {
    case 'amount':
      return addFee(initial, fee.amount, 0, fee.isOffsetEnabled);
    case 'percentage':
      return addFee(initial, 0, fee.percentage, fee.isOffsetEnabled);
    case 'combination':
      return addFee(initial, fee.amount, fee.percentage, fee.isOffsetEnabled);
    case 'greater':
      const percentage = addFee(initial, 0, fee.percentage, fee.isOffsetEnabled);
      const flat = addFee(initial, fee.amount, 0, fee.isOffsetEnabled);
      return Math.max(percentage, flat);
    default: return initial;
  }
}

const getFeebyType = (
  feeStructure: FeeStructure,
  achFeeStructure: FeeStructure,
  paymentType: PaymentType,
): LiveViewFee[] => {
  if (paymentType === 'ach') {
    return achFeeStructure?.fees;
  }
  if (paymentType === 'credit_card') {
    // this is for the backwards compatibility: fees for credit cards on old forms do not have 'paymentMethodType' field
    const fee: LiveViewFee | undefined = feeStructure?.fees?.find(f => f.paymentMethodType === 'credit_card' || !f.paymentMethodType);
    return fee ? [fee] : [];
  }

  const fee: LiveViewFee | undefined = feeStructure?.fees?.find(f => f.paymentMethodType === paymentType);

  return fee ? [fee] : [];
};

// * This function does not handle cases where there are multiple fees and percentages
// * Especially if percentages are calculated before other fees as they need be applied to the total
export function calculatePayment(
  { amountSource, feeStructure, achFeeStructure, amountSourceType }: LiveViewPaymentConfig,
  formState: FormState,
  paymentType: PaymentType,
):[number, number, number] {
  const baseAmount: number = getBaseAmount({ amountSource, amountSourceType }, formState);
  const fee: LiveViewFee[] = getFeebyType(feeStructure, achFeeStructure, paymentType);

  // If base amount less than or equal to zero, return zeros for total and fees
  if (baseAmount <= 0) return [0, baseAmount, 0];

  // Convert base amount to Big for accuracy and round to 2 decimal places
  const roundedBaseAmount = Big(baseAmount).round(2).toNumber();

  // If no fee structure/fees, return the rounded base amount
  if (!fee?.length) return [roundedBaseAmount, roundedBaseAmount, 0];

  // Get total amount with fees and round to 2 decimal places
  const totalAmount = fee.reduce(addFeeType, roundedBaseAmount);

  // Get fee amount by subtracting base amount from total amount
  const feeAmount = Big(totalAmount).minus(roundedBaseAmount).round(2).toNumber();

  return [totalAmount, roundedBaseAmount, feeAmount];
}

export function getFeeName({feeStructure}: LiveViewPaymentConfig) {
  // ENG-2445: modal doesn't have enough space for more than one fee name
  return feeStructure?.fees?.find(fee => !!fee.customFeeName)?.customFeeName || 'Fee';
}

export const getReviewPages = (form?: LiveViewForm): ReviewSectionPage[] => {
  if (!form?.pages?.length) return [];

  const result: ReviewSectionPage[] = [];
  for (let i = 0; i < form?.pages?.length; i++) {
    const page: LiveViewPage | undefined = form?.pages[i];
    if (!page) break;

    // page should contain at least one element to be rendered
    const element: LiveViewElement | undefined = page.rows?.flatMap((row: LiveViewRow) => row.elements).
      find((el: LiveViewElement) =>
        // element should not be excluded and it's type should be supported for the preview mode
        el.excludeFromPreview === false && elementsForPreview.includes(el.elementType));

    // we need this to save original page index
    if (element) {
      result.push({ page, pageIndex: i });
    }
  }

  return result;
};

export const getEffectiveLastPage = (form: LiveViewForm | undefined, shouldDisplayPreviewPage: boolean): number => {
  if (!form?.pages?.length) return 0;
  return shouldDisplayPreviewPage ? form.pages.length : form.pages.length - 1;
};

/** Receives showLabel param and converts it to boolean value. */
export const showLabelToBoolean = (showLabel: ShowLabelValue | undefined): boolean =>
  (showLabel === undefined || showLabel === null || showLabel === 't') ? true : false;

/** Receives readonly param and converts it to boolean value. */
export const readonlyToBoolean = (readonly: ReadOnlyValue | undefined): boolean =>
  (readonly && readonly === 't') ? true : false;

/** Receives showChooseOne param and converts it to boolean value. */
export const showChooseOneToBoolean = (showChooseOne: ShowChooseOneValue | undefined): boolean =>
  (showChooseOne && showChooseOne === 'false') ? false : true;

/** Returns CSS class based on alignment value. */
export const getAlignmentClass = (alignment: LabelAlignment, styles: any): string => {
  switch (alignment) {
    case 'right':
      return styles.AlignRight;
    case 'center':
      return styles.AlignCenter;
    case 'left':
    default:
      return styles.AlignLeft;
  }
};

/** Returns value for 'max' attribute.
 * This is workaround: maxlength prop doesn't work with input type='number',
 * so we're limiting user's input by 'max' attribute
 */
export const getMaxValue = (maxVal: string, maxLength: string): string => {
  if (!maxVal && !maxLength) {
    return '';
  }
  if (!maxLength) {
    return maxVal;
  }
  const maxValue: number = Number.parseInt(maxVal, 10);
  const length: number = Number.parseInt(maxLength, 10);
  const maxLengthVal: number = 10 ** length - 1;
  if (!maxValue) {
    return maxLengthVal.toString();
  }
  return Math.min(maxValue, maxLengthVal).toString();
};

export const countCompletedIdVerifications = (
  formData: FormData,
  formState: FormState,
): number => formData.form?.pages
  .flatMap(page => page.rows)
  .flatMap(row => row.elements)
  .filter(element =>
    element.elementType === 'id_verification' &&
      formState[element.id]?.extraData?.status === 'COMPLETED'
  )
  .length ?? 0;

export const calculateDigitalServiceFeeAmount = (
  formData: FormData,
  formState: FormState
): number => {
  if (!formData.form?.digitalServiceFeeConfig) {
    return 0;
  }
  const { digitizationFlatFeeAmount, idVerificationFeeAmount } = formData.form.digitalServiceFeeConfig;
  const idVerificationsCount = countCompletedIdVerifications(formData, formState);
  const bigIdVerificationFees = Big(idVerificationFeeAmount).times(idVerificationsCount);
  const bigDigitizationFlatFee = Big(digitizationFlatFeeAmount);
  const bigTotal = bigIdVerificationFees.plus(bigDigitizationFlatFee);
  const result = bigTotal.round(2).toNumber();
  return result;
};
