import React, { useState, MouseEvent, FC, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import NgSettingsToggle from './NgSettingsToggle';
import ConfirmationModal from 'containers/NgSettings/ConfirmationModal';
import styles from './NgSettingsStyles.css';
import { setNgLiveFormsInAccount } from 'actions/ngSettingsPage';


interface NgSettingsProps {
  ngLiveSettingStatus: boolean;
  ngLiveSettingsUpdateHandler: (status: boolean) => void;
}

const NgSettings: FC<NgSettingsProps> = ({ ngLiveSettingsUpdateHandler, ngLiveSettingStatus }) => {
  const [ngViewOnAllForms, setNgViewOnAllForms] = useState<boolean>(ngLiveSettingStatus);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const toggleChangeHandler = (): void => setNgViewOnAllForms(prevState => !prevState);

  const saveChangesHandler = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setIsConfirmationModalOpen(true);
  };

  const onContinueHandler = (): void => {
    ngLiveSettingsUpdateHandler(ngViewOnAllForms);
    setIsConfirmationModalOpen(false);
  };

  useEffect(() => {
    setNgViewOnAllForms(ngLiveSettingStatus);
  }, [ngLiveSettingStatus]);

  return (
    <div className={styles.ngSettingsContainer}>
      <div className={styles.ngSettingsContent}>
        <h1 className={styles.ngSettingsTitle}>Spark - NG Settings</h1>
        <p className={styles.ngSettingsDescription}>
          Enabling NG view will render your forms with next generation view,
          which has a better user interface and supports additional functionalities.
          Some forms are not eligible for NG view because of unsupported legacy
          features and will continue to function in legacy format.
          You can also specifically exclude individual NG forms back to legacy from form settings.
        </p>
        <div className={styles.ngSettingsToggleContainer}>
          <span className={styles.ngSettingsToggleText}>Turn on NG view on all eligible forms</span>
          <NgSettingsToggle name={'Ng Settings Toggle'} onChange={toggleChangeHandler} checked={ngViewOnAllForms}/>
        </div>
        <div className={styles.ngSettingsButtonContainer}>
          <button
            onClick={saveChangesHandler}
            className={styles.ngSettingsSaveButton}
            aria-label='Save changes for NG settings'
            data-testid='save-button'
          >
            Save Changes
          </button>
        </div>
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          ngViewOnAllForms={ngViewOnAllForms}
          onCancel={() => setIsConfirmationModalOpen(false)}
          onContinue={onContinueHandler}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ngLiveSettingsUpdateHandler: bindActionCreators(setNgLiveFormsInAccount, dispatch),
});

const mapStateToProps = (state: any) => {
  const webFormNgLiveViewStatus = state?.features?.WEBFORM_NG_LIVE_VIEW ?? false;

  return {
    ngLiveSettingStatus: webFormNgLiveViewStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NgSettings);
